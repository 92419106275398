import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "table-info-panel" },
    [
      _c(
        VRow,
        {
          staticClass: "ma-0 pa-0",
          staticStyle: { padding: "10px 0px !important" },
        },
        [
          _c(VCardTitle, { staticClass: "ma-0 pa-0" }, [
            _vm._v(_vm._s(_vm.data.name) + " 테이블"),
          ]),
          _c(VSpacer),
          _c(
            VBtn,
            {
              staticStyle: { "margin-right": "5px" },
              attrs: { color: "primary", small: "" },
              on: {
                click: function ($event) {
                  return _vm.handleUploadNodeInfo()
                },
              },
            },
            [_vm._v("자바로 변환")]
          ),
          _c(
            VBtn,
            {
              attrs: { icon: "", small: "" },
              on: { click: function ($event) {} },
            },
            [
              _c("Icon", {
                attrs: {
                  icon: "iconamoon:arrow-down-2",
                  width: "20",
                  height: "20",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("table", { staticClass: "table" }, [
        _vm._m(0),
        _c(
          "tbody",
          _vm._l(_vm.filteredData, function (value, key) {
            return _c("tr", { key: key }, [
              _c("td", [_vm._v(_vm._s(key))]),
              _c("td", [_vm._v(_vm._s(value))]),
            ])
          }),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col" } }, [_vm._v("속성 이름")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("속성 값")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }