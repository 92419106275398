var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-area" },
    [
      _c("VueMarkdown", {
        staticClass: "markdown-body",
        attrs: { source: _vm.serverResponse },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }