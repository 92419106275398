import { VCard } from 'vuetify/lib/components/VCard';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VCard,
    {
      staticClass: "mb-3",
      class: { "drag-over": _vm.isDragging },
      on: {
        dragover: function ($event) {
          $event.preventDefault()
          return _vm.handleDragOver.apply(null, arguments)
        },
        dragenter: function ($event) {
          $event.preventDefault()
          return _vm.handleDragEnter.apply(null, arguments)
        },
        dragleave: function ($event) {
          $event.preventDefault()
          return _vm.handleDragLeave.apply(null, arguments)
        },
        drop: function ($event) {
          $event.preventDefault()
          return _vm.handleFileDrop.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "label",
        { staticClass: "custom-file-label", attrs: { for: "formFile" } },
        [_vm._v("\n    " + _vm._s(_vm.fileName) + "\n  ")]
      ),
      _c("input", {
        staticClass: "form-control d-none",
        attrs: { type: "file", id: "formFile" },
        on: { change: _vm.handleFileUpload },
      }),
      _vm.isDragging
        ? _c("div", { staticClass: "drop-zone" }, [
            _vm._v("\n    " + _vm._s(_vm.$t("FileSelect.upload")) + "\n  "),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }