var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _c("app-menu", { ref: "appMenu", staticClass: "menu-section" }),
      _c(
        "div",
        { staticClass: "content-section" },
        [
          _c("file-select", {
            on: {
              "send-success": _vm.handleSendData,
              "next-sequence": _vm.handleSequence,
              error: _vm.handleError,
              loading: _vm.setLoading,
            },
          }),
          _c("graph-show", {
            attrs: { data: _vm.CyperQuery || {}, loading: _vm.isLoading },
            on: { "next-sequence": _vm.handleSequence },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }